// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapLogin {
    height: 100%;
}
.sideBanner {
    background-color: rgb(76,98,128);
}`, "",{"version":3,"sources":["webpack://./src/containers/LoginPage/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,gCAAgC;AACpC","sourcesContent":[".wrapLogin {\n    height: 100%;\n}\n.sideBanner {\n    background-color: rgb(76,98,128);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
