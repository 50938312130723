// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapVHeader {
    width: 100%;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.vhProfile {
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: 20px;
}
.vhLogo {
    margin: 0px 30px;
}
.logobutton {
    border: none;
    background-color: inherit;
    cursor: pointer;
}
.btnLoginVolunteer {
    background-color: #0080BC;
    border: none;
    padding: 5px 20px;
    margin-right: 20px;
    color: white;
    border-radius: 5px;
}
.btnSignupVolunteer {
    background-color: green;
    border: none;
    padding: 5px 20px;
    margin-right: 20px;
    color: white;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/VolunteerHeader/VolunteerHeader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,uBAAuB;IACvB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".wrapVHeader {\n    width: 100%;\n    height: 74px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n.vhProfile {\n    width: 240px;\n    display: flex;\n    align-items: center;\n    justify-content: right;\n    margin-right: 20px;\n}\n.vhLogo {\n    margin: 0px 30px;\n}\n.logobutton {\n    border: none;\n    background-color: inherit;\n    cursor: pointer;\n}\n.btnLoginVolunteer {\n    background-color: #0080BC;\n    border: none;\n    padding: 5px 20px;\n    margin-right: 20px;\n    color: white;\n    border-radius: 5px;\n}\n.btnSignupVolunteer {\n    background-color: green;\n    border: none;\n    padding: 5px 20px;\n    margin-right: 20px;\n    color: white;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
